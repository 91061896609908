/**
 * 상품 화면에서 주로 쓰이는 공통함수를 탑재한다.
 */

export default {
  data() {
    return {
      CUR_YEAR: new Date().getFullYear() + ''
    };
  },
  created() {
  },
  methods: {
    /**
     * _cat 을 기반으로 상품 생성 위치를 badge 형태로 반환한다.
     */
    badgeCat(_cat) {
      const appMap = {
        'feed': '<span class="badge badge-info">FEED</span>',
        'new-feed': '<span class="badge badge-info">FEED</span>',
        'hub': '<span class="badge badge-success">HUB</span>',
        'api_partner': '<span class="badge alert-success" title="파트너 API">API</span>',
        'partner': '<span class="badge alert-primary">파트너센터</span>',
        'new-partner': '<span class="badge alert-primary">파트너센터</span>'
      };
      return _cat ? appMap[_cat.split(/[#.]/)[0]] : '-';
    },
    /**
     * _cat 을 기반으로 상품 생성 위치를 text 로 반환한다.
     */
    cat2capp(_cat) {
      const appMap = {
        'feed': 'FEED',
        'new-feed': 'FEED',
        'hub': 'HUB',
        'api_partner': 'API',
        'partner': '파트너센터',
        'new-partner': '파트너센터'
      };
      return _cat ? appMap[_cat.split(/[#.]/)[0]] || '기타' : '-';
    },
    reduceDT(dt) {
      if (dt.slice(0, 4) !== this.CUR_YEAR) {
        return dt.slice(0, 10); // YYYY-MM-DD
      }
      return dt.slice(5, 16); // MM-DD HH:mm
    }
  }
}
